import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCardMinimal from '../../../components/post-c-mini-sdgs/post-c-mini-sdgs';
import Pagination from '../../../components/pagination/pagination';
import BlogPostsWrapper, { SecTitle } from './style';
import {
  AboutImage,
  SdgsImage,
} from './style';

import Styles from './index.module.css';


type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 325, maxHeight: 325, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;
  const TotalPage = Data.allSitePage.nodes[0].context.numPages;
  const CurrentPage = Data.allSitePage.nodes[0].context.currentPage;

  return (
    <BlogPostsWrapper>
      <AboutImage>

<a href="/tags/sdgs-1"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_01.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_02.png"/></a>
<a href="/tags/sdgs-3"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_03.png"/></a>
<a href="/tags/sdgs-4"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_04.png"/></a>
<a href="/tags/sdgs-5"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_05.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_06.png"/></a>
<a href="/tags/sdgs-7"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_07.png"/></a>
<a href="/tags/sdgs-8"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_08.png"/></a>
<a href="/tags/sdgs-9"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_09.png"/></a>
<a href="/tags/sdgs-10"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_10.png"/></a>
<a href="/tags/sdgs-11"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_11.png"/></a>
<a href="/tags/sdgs-12"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_12.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_13.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_14.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_15.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_16.png"/></a>
<a href="#"><img style={{width:"15%",margin:"2px",opacity:"0.1"}} src="/sdgsicon/sdg_17.png"/></a>
<a href="/sdgstheme"><img style={{width:"15%",margin:"2px"}} src="/sdgsicon/sdg_all.png"/></a>

      </AboutImage>
{/* 
      {Posts.map(({ node }: any) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <PostCardMinimal
            key={node.fields.slug}
            title={title}
            image={
              node.frontmatter.cover == null
                ? null
                : node.frontmatter.cover.childImageSharp.fluid
            }
            url={node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        );
      })}

      {TotalPage >> 1 ? (
        <Pagination
          nextLink="/page/2"
          currentPage={CurrentPage}
          totalPage={TotalPage}
        />
      ) : (
        ''
      )}


 */}

    </BlogPostsWrapper>
  );
};

export default Posts;
